<template>
  <div>
       <v-row class="ma-0 px-4 pb-0 pt-4 align-center">
      <v-col cols="2" class="pa-0"
        ><h3 width="100" height="24" class=".text-lg-h6 mb-5">Follow Up</h3></v-col
      >
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0">
        <v-text-field
          v-model="search"
          single-line
          dense
          outlined
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
        ></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>

      <v-col cols="2" class="pa-0 d-flex justify-end"
        ><v-btn class="mb-6"
          depressed
          color="align-center justify-center  text-none pa-0 bgr_clr_btn_save "
          min-width="155px"
          @click="$router.push('/Followupdetail')"
        >
          Create FollowUps
        </v-btn></v-col
      >
    </v-row>
    <v-data-table 
     
        :headers="headers"
        v-bind:items="this.collectionList"
        :search="search"
        :value="this.collectionList"
        item-key="name"
        class="elevation-1  px-4 mx-5"
        id="table"
      >
       <template slot="this.collectionList" slot-scope="">
          <tr  >
            <th
              v-for="(item, index) in this.collectionList"
               :key="index"
            class="tableRowHeight"
           

            ></th>
          </tr>
        </template>
        <template v-slot:item="props">
          <tr>
            
            <td>{{ props.index + 1 }}</td>
            <td>{{ props.item.customer_name }}</td>
            <td>{{ props.item.aadhar_no }}</td>
            <td>{{ props.item.mobile_number }}</td>
            <td>{{ props.item.Credits }}</td>
            <td>{{ props.item.address }}</td>
            <td>{{ props.item.customer_type }}</td>
            <td>{{ props.item.pan_card }}</td>
           
          </tr>
        </template>
      </v-data-table>
  </div>
</template>

<script>
export default {
   data(){
     return{
        search: "",
            headers: [
        {
          text: "S.NO",
          align: "start",
          sortable: false,
          value: "customer_name",
          class: "",
        },
        { text: "Name", value: "customer_name ", sortable: false },
        { text: "AADHAR Card", value: "aadhar_no", sortable: false },
        { text: "Mobile Number", value: "mobile_number", sortable: false },
        { text: "Credits", value: "Credits", sortable: false },
        { text: "Area", value: "address", sortable: false },
        { text: "Customer Type", value: "customer_type", sortable: false },
        { text: "PAN CARD", value: "pan_card", sortable: false },
  
        ],
        collectionList: [
          {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
          {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
           {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
           {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
          {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
          {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
           {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
          {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
          {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
           {
            aadhar_no: '146820357691',
            customer_name: 'DINESH',
            mobile_number: 9978534321,
            Credits: 24,
            address: 'CHENNAI',
            customer_type: 'DIRECT',
            pan_card:'GJKLN7854Q'
          },
        ],
          methods:{
          // createCustomer(val){
          //   console.log(val)
          // }
     }
     }
   
   }
}
</script> 

<style>
.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 64px;
    padding: 0 16px;
}
</style>
<template>
  <div>
      <v-row class="ma-0 py-2 pl-2 pl-4 pr-4">
          <div>
              <label class="font-weight-bold">Contacts</label>
          </div>
      </v-row>
           <v-row class="ma-0 py-2 pl-2 pl-4 pr-4">
          <div>
       <!-- <v-slide-group v-model="infoType" class="pa-4" mandatory hide-arrows>
          <v-slide-item
            v-for="(item, i) in Items"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <v-btn
              depressed
              :color="active ? 'activeColor' : 'unActiveColor'"
              height="26"
              min-width="80"
              width="100px"
              class="fsize13 ma-2 text-center white--text text-capitalize"
              :value="item"
              @click="toggle"
            >
              {{ item }}
            </v-btn>
          </v-slide-item>
        </v-slide-group> -->
      <v-btn
        depressed
        height="26"
        width="150"
        color="#E6E6E6"
        class="fsize12 headBtns rounded-sm"
        :class="this.district ? 'active' : ''"
        @click="districtData()"
        >District Officials</v-btn
      ><span></span>
      <v-btn
        depressed
        height="26"
        width="90"
        color="#E6E6E6"
        class="fsize12 headBtns rounded-sm ml-2"
        :class="this.contacts ? 'active' : ''"
        @click="contactsdata()"
        >Contacts</v-btn
      > 
          </div>
          
      </v-row>
        <v-divider class="mx-4"></v-divider>
     
<v-row class="ma-0 pa-4 align-center">
      <v-col cols="2" class="pa-0 mb-4"
        ><h3 width="100" height="24" class=".text-lg-h6">Contacts</h3></v-col
      >
      <v-col class="pa-0" cols="1"></v-col>
      <v-col cols="6" class="pa-0">
        <v-text-field
          v-model="search"
          single-line
          dense
          outlined
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
        ></v-text-field>
      </v-col>
      <v-col class="pa-0" cols="1"></v-col>

      <v-col cols="2" class="pa-0 d-flex justify-end"
        ><v-btn class="mb-8"
          depressed
          color="align-center justify-center text-none pa-0 bgr_clr_btn_save "
          min-width="155px"
          @click="createCivilWork()"
        >
          Create New
        </v-btn></v-col
      >
    </v-row>

  


     
     <v-data-table 
        :headers="headers"
        v-bind:items="this.loanDetails"
        :search="search"
        :value="this.loanDetails"
        item-key="name"
        class="elevation-1 px-4 mx-5 subtitle "
        id="table"
      >
       <template slot="this.customerList" slot-scope="">
          <tr>
            <th
              v-for="(item, index) in this.loanDetails"
               :key="index"
            class="tableRowHeight"
           

            ></th>
          </tr>
        </template>
        <template v-slot:item="props">
          <tr @click="loanRecords(props.index)">
            
            <td>{{ props.index + 1 }}</td>
            <td>{{ props.item.customer_name}}</td>
            <td>{{ props.item.loan_type }}</td>
            <td>{{ props.item.loan_amount }}</td>
            <td>{{ props.item.tenure }}</td>
            <td>{{ props.item.roi }}</td>
            <td>{{ props.item.loan_start_date }}</td>
            <td>{{ props.item.cash_received }}</td>
          </tr>
        </template>
      </v-data-table>

      
  </div>
</template>

<script>
export default {
 data: () => ({
    district: true,
    contacts: false,
    //Items:['District Officials', 'Contacts']
     search: "",
      desserts: [
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
        {
          Direction: "IN",
          Type: "159",
          Transactionreference: "55qwertyui-55",
          SenderBIC: "5951",
          ReceiverBIC: "MCLOC4654",
          Currency: "54663",
          Amount: "1231654655",
          Status: "852036",
          Creationdate: "96783",
        },
      ],
  }),
  methods:{
   districtData(){
       this.district = true;
       this.contacts = false
   },
   contactsdata(){
    this.contacts = true
     this.district = false
   }
  }
}
</script> 

<style>


.v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
}
.headBtns.active {
  background-color: #007aff !important;
  color: #ffffff !important;
  border-radius: 2px !important;
  /* border: 1px solid #00cd97 !important; */
}
</style>
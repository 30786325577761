<template>
  <v-responsive min-width="1302">
    <v-app>
          <div class="padd-t-8  padd-l-24">
                    <!-- <span class="fsize12 clr-1867c0"
                                    >Customer / New Customer Creation</span
                                  > -->
                    <v-breadcrumbs :items="items">
                        <template v-slot:item="{ item }">
                      <v-breadcrumbs-item
                        class="fsize12 clr-1867c0"
                        @click="$router.push('/projects')"
                        :disabled="item.disabled"
                      >
                        {{ item.text.toUpperCase() }}
                      </v-breadcrumbs-item>
 </template>
          </v-breadcrumbs>
        </div>
        <div class="padd-t-16 padd-b-16 padd-l-24">
          <span class="fsize20 clr-000 fw-bold">New Project Creation</span>
        </div>
        <div>
          <v-card class="ml-6 mr-6 wholeCard padd-t-24 padd-0-40 min-height_1308">
              <v-row class="marg-0">
                <!-- <div class="marg-t-16"> -->
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div class="fsize14 clr-000 fw-bold padd-t-24">
                    Project Details
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  <div class="padd-t-24">
                    <div class="w-464 h-72">
                      <div class="custom-label fsize14 clr_55585a">Name</div>

                      <input
                        type="text"
                        name=""
                        class="
                          w-464
                          h-40
                          border_radius
                          padd-8-16
                          border-input
                          fsize14
                          outLine_none
                        "
                        v-model="name"
                        :disabled="disabledNext == 1"
                        maxlength="100"
                        placeholder="Enter your Name"
                        autocomplete="off"
                        :class="{
                          'is-invalid': submitted && this.name == '',
                        }"
                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                      />
                      <div
                        class="fsize12 text-danger marg-t-4"
                        v-if="this.submitted && this.name == ''"
                      >
                        Please Enter The Name
                      </div>
                    </div>

                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          AADHAR CARD
                        </div>

                        <input
                          type="text"
                          name=""
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          :disabled="disabledNext == 1"
                          v-model="aadharCard"
                          maxlength="12"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          placeholder="Enter your AADHAR CARD"
                          autocomplete="off"
                          :class="{
                            'is-invalid': submitted && this.aadharCard == '',
                          }"
                        />
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="this.submitted && this.aadharCard == ''"
                        >
                          Please Enter AADHAR CARD Number
                        </div>
                      </div>
                    </div>


                    <v-row class="marg-0 marg-t-10">
                      <!-- <div class="marg-t-16"> -->

                      <v-col
                        cols="5"
                        sm="5"
                        md="5"
                        xs="5"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72">
                          <div class="custom-label fsize14 clr_55585a">
                            Mobile Number
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            :disabled="disabledNext == 1"
                            v-model="mobileNo"
                            id="Name1"
                            placeholder="Enter your Mobile Number"
                            autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            maxlength="10"
                            :class="{
                              'is-invalid': submitted && this.mobileNo == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submitted && this.mobileNo == ''"
                          >
                            Please Enter Mobile Number
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="5"
                        sm="5"
                        md="5"
                        xs="5"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72 marg-l-12 w-225">
                          <div class="custom-label fsize14 clr_55585a">
                            Whatsapp Number
                            <button @click="copyTextValue()">Mob No</button>
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            :disabled="disabledNext == 1"
                            v-model="whatsappNo"
                            maxlength="10"
                            id="Name2"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            placeholder="Enter your  Whatsapp Number"
                            autocomplete="off"
                            :class="{
                              'is-invalid': submitted && this.whatsappNo == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submitted && this.whatsappNo == ''"
                          >
                            Please Enter Whatsapp Number
                          </div>
                        </div>
                      </v-col>

                      <v-col
                        cols="2"
                        sm="2"
                        md="2"
                        xs="2"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div>
                          <div class="marg-l-24 marg-t-20">
                            <v-btn
                              class="
                                btn_width_96
                                h-40
                                brl_clr_btn
                                fsize14
                                textTransform_None
                                bgr_clr_btn_save
                              "
                              depressed
                              plain
                              retain-focus-on-click
                              ripple
                              @click="customerdetailsSave()"
                            >
                              Next
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <!-- <div class="">
                      <div class="fright">
                        <v-btn
                          class="
                            btn_width_96
                            h-40
                            brl_clr_btn
                            fsize14
                            textTransform_None
                            bgr_clr_btn_save
                          "
                          depressed
                          plain
                          retain-focus-on-click
                          ripple
                          @click="customerdetailsSave()"
                        >
                          Save
                        </v-btn>
                      </div>
                    </div> -->
                  </div>
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                </v-col>
                <!-- </div> -->
              </v-row>
                   <div>
                <hr class="lineBelowHead marg-t-32" />

                <v-row class="marg-0">
                  <!-- <div class="marg-t-16"> -->
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div class="fsize14 clr-000 fw-bold padd-t-24">
                      Address Details
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  </v-col>
                </v-row>
              </div>

          </v-card>
        </div>
    </v-app>
  </v-responsive>
</template>

<script>
export default {
  data() {
    return {
      submitted: false,
      customerType: "",
      aadharCard: "",
      name: "",
      dealerName: "",
      whatsappNo: "",
      mobileNo: "",
      items: [
        { text: "Project", disabled: false, href: "Project" },
        { text: "Project Detail", disabled: true, href: "breadcrumbs_link_1" },
      ],
      
    };
  },


  methods: {

    mounted() {},
  },
};
</script> 

<style>
/* .disableInput {
  background-color: #e9ecef;
  opacity: 1;
} */
.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: none;
  background: #e9ecef !important;
  height: 40px !important;
}
[disabled] {
  cursor: default;
  background: #e9ecef !important;
}
.cursorpointer {
  cursor: pointer !important;
}
/* ::v-deep .v-data-table__empty-wrapper {
        display: none !important;
    }
    .v-application .v-autocomplete__content.menuable__content__active {
  display: none !important;
} */
</style>
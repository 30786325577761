var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-responsive',{attrs:{"min-width":"1302"}},[_c('v-app',[_c('div',{staticClass:"padd-t-8  padd-l-24"},[_c('v-breadcrumbs',{attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{staticClass:"fsize12 clr-1867c0",attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.$router.push('/Civilworks')}}},[_vm._v(" "+_vm._s(item.text.toUpperCase())+" ")])]}}])})],1),_c('div',{staticClass:"padd-t-16 padd-b-16 padd-l-24"},[_c('span',{staticClass:"fsize20 clr-000 fw-bold"},[_vm._v("New Civilwork Creation")])]),_c('div',[_c('v-card',{staticClass:"ml-6 mr-6 wholeCard padd-t-24 padd-0-40 min-height_1308"},[_c('v-row',{staticClass:"marg-0"},[_c('v-col',{staticClass:"pa-0 padd-0",attrs:{"cols":"3","sm":"3","md":"3","xs":"3"}},[_c('div',{staticClass:"fsize14 clr-000 fw-bold padd-t-24"},[_vm._v(" Civil Work Details ")])]),_c('v-col',{staticClass:"pa-0 padd-0",attrs:{"cols":"6","sm":"6","md":"6","xs":"6"}},[_c('div',{staticClass:"padd-t-24"},[_c('div',{staticClass:"w-464 h-72"},[_c('div',{staticClass:"custom-label fsize14 clr_55585a"},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"\n                         w-464\n                         h-40\n                         border_radius\n                         padd-8-16\n                         border-input\n                         fsize14\n                         outLine_none\n                       ",class:{
                         'is-invalid': _vm.submitted && this.name == '',
                       },attrs:{"type":"text","name":"","disabled":_vm.disabledNext == 1,"maxlength":"100","placeholder":"Enter your Name","autocomplete":"off","onkeypress":"return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),(this.submitted && this.name == '')?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Enter The Name ")]):_vm._e()]),_c('div',{staticClass:"marg-t-16"},[_c('div',{staticClass:"w-464 h-72"},[_c('div',{staticClass:"custom-label fsize13 clr_55585a"},[_vm._v(" AADHAR CARD ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.aadharCard),expression:"aadharCard"}],staticClass:"\n                           w-464\n                           h-40\n                           border_radius\n                           padd-8-16\n                           border-input\n                           fsize14\n                           outLine_none\n                         ",class:{
                           'is-invalid': _vm.submitted && this.aadharCard == '',
                         },attrs:{"type":"text","name":"","disabled":_vm.disabledNext == 1,"maxlength":"12","oninput":"this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*?)\\..*/g, '$1');","placeholder":"Enter your AADHAR CARD","autocomplete":"off"},domProps:{"value":(_vm.aadharCard)},on:{"input":function($event){if($event.target.composing){ return; }_vm.aadharCard=$event.target.value}}}),(this.submitted && this.aadharCard == '')?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Enter AADHAR CARD Number ")]):_vm._e()])]),_c('v-row',{staticClass:"marg-0 marg-t-10"},[_c('v-col',{staticClass:"pa-0 padd-0 max_w-225",attrs:{"cols":"5","sm":"5","md":"5","xs":"5"}},[_c('div',{staticClass:"h-72"},[_c('div',{staticClass:"custom-label fsize14 clr_55585a"},[_vm._v(" Mobile Number ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mobileNo),expression:"mobileNo"}],staticClass:"\n                             w-225\n                             h-40\n                             border_radius\n                             padd-8-16\n                             border-input\n                             fsize14\n                             outLine_none\n                           ",class:{
                             'is-invalid': _vm.submitted && this.mobileNo == '',
                           },attrs:{"type":"text","name":"","disabled":_vm.disabledNext == 1,"id":"Name1","placeholder":"Enter your Mobile Number","autocomplete":"off","oninput":"this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*?)\\..*/g, '$1');","maxlength":"10"},domProps:{"value":(_vm.mobileNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.mobileNo=$event.target.value}}}),(this.submitted && this.mobileNo == '')?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Enter Mobile Number ")]):_vm._e()])]),_c('v-col',{staticClass:"pa-0 padd-0 max_w-225",attrs:{"cols":"5","sm":"5","md":"5","xs":"5"}},[_c('div',{staticClass:"h-72 marg-l-12 w-225"},[_c('div',{staticClass:"custom-label fsize14 clr_55585a"},[_vm._v(" Whatsapp Number "),_c('button',{on:{"click":function($event){return _vm.copyTextValue()}}},[_vm._v("Mob No")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.whatsappNo),expression:"whatsappNo"}],staticClass:"\n                             w-225\n                             h-40\n                             border_radius\n                             padd-8-16\n                             border-input\n                             fsize14\n                             outLine_none\n                           ",class:{
                             'is-invalid': _vm.submitted && this.whatsappNo == '',
                           },attrs:{"type":"text","name":"","disabled":_vm.disabledNext == 1,"maxlength":"10","id":"Name2","oninput":"this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*?)\\..*/g, '$1');","placeholder":"Enter your  Whatsapp Number","autocomplete":"off"},domProps:{"value":(_vm.whatsappNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.whatsappNo=$event.target.value}}}),(this.submitted && this.whatsappNo == '')?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Enter Whatsapp Number ")]):_vm._e()])]),_c('v-col',{staticClass:"pa-0 padd-0 max_w-225",attrs:{"cols":"2","sm":"2","md":"2","xs":"2"}},[_c('div',[_c('div',{staticClass:"marg-l-24 marg-t-20"},[_c('v-btn',{staticClass:"\n                               btn_width_96\n                               h-40\n                               brl_clr_btn\n                               fsize14\n                               textTransform_None\n                               bgr_clr_btn_save\n                             ",attrs:{"depressed":"","plain":"","retain-focus-on-click":"","ripple":""},on:{"click":function($event){return _vm.customerdetailsSave()}}},[_vm._v(" Next ")])],1)])])],1)],1)]),_c('v-col',{staticClass:"pa-0 padd-0",attrs:{"cols":"3","sm":"3","md":"3","xs":"3"}})],1),_c('div',[_c('hr',{staticClass:"lineBelowHead marg-t-32"}),_c('v-row',{staticClass:"marg-0"},[_c('v-col',{staticClass:"pa-0 padd-0",attrs:{"cols":"3","sm":"3","md":"3","xs":"3"}},[_c('div',{staticClass:"fsize14 clr-000 fw-bold padd-t-24"},[_vm._v(" Address Details ")])]),_c('v-col',{staticClass:"pa-0 padd-0",attrs:{"cols":"6","sm":"6","md":"6","xs":"6"}},[_c('div',{staticClass:"row marg-0 padd-t-24"},[_c('div',{staticClass:"w-464 h-72"},[_c('div',{staticClass:"custom-label fsize14 clr_55585a"},[_vm._v(" District ")]),_c('v-select',{staticClass:"fsize14 marg_top w-464",class:{
                           'is-invalid h-40':
                             _vm.submittedPersonel && this.customerType == '',
                         },attrs:{"disabled":_vm.disabled == 0,"items":_vm.customerTypeItem,"label":"","dense":"","outlined":""},model:{value:(_vm.customerType),callback:function ($$v) {_vm.customerType=$$v},expression:"customerType"}}),(
                           this.submittedPersonel && this.customerType == ''
                         )?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Choose Customer Type ")]):_vm._e()],1),(this.customerType == 'Dealer')?_c('div',[_c('div',{staticClass:"marg-t-16"},[_c('div',{staticClass:"w-464 h-72"},[_c('div',{staticClass:"custom-label fsize13 clr_55585a"},[_vm._v(" Taluk ")]),_c('v-select',{staticClass:"fsize14 marg_top w-464",class:{
                               'is-invalid h-40':
                                 _vm.submittedPersonel && this.area == '',
                             },attrs:{"disabled":_vm.disabled == 0,"items":_vm.dealerlists,"label":"","dense":"","outlined":""},model:{value:(_vm.dealerName),callback:function ($$v) {_vm.dealerName=$$v},expression:"dealerName"}})],1)])]):_vm._e(),(this.customerType == 'Broker')?_c('div',[_c('div',{staticClass:"marg-t-16"},[_c('div',{staticClass:"w-464 h-72"},[_c('div',{staticClass:"custom-label fsize13 clr_55585a"},[_vm._v(" Block ")]),_c('v-select',{staticClass:"fsize14 marg_top w-464",class:{
                               'is-invalid h-40':
                                 _vm.submittedPersonel && this.area == '',
                             },attrs:{"disabled":_vm.disabled == 0,"items":_vm.brokerlists,"label":"","dense":"","outlined":""},model:{value:(_vm.brokerName),callback:function ($$v) {_vm.brokerName=$$v},expression:"brokerName"}})],1)])]):_vm._e(),_c('div',{staticClass:"marg-t-16"},[_c('div',{staticClass:"w-464 h-72"},[_c('div',{staticClass:"custom-label fsize13 clr_55585a"},[_vm._v(" Area ")]),_c('v-select',{staticClass:"fsize14 marg_top w-464",class:{
                             'is-invalid h-40':
                               _vm.submittedPersonel && this.area == '',
                           },attrs:{"disabled":_vm.disabled == 0,"items":_vm.arealistsone,"label":"","dense":"","outlined":""},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}}),(this.submittedPersonel && this.area == '')?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Choose Area ")]):_vm._e()],1)]),_c('div',{staticClass:"marg-t-16"},[_c('div',{staticClass:"w-464 h-72"},[_c('div',{staticClass:"custom-label fsize13 clr_55585a"},[_vm._v(" Married ")]),_c('v-select',{staticClass:"fsize14 marg_top w-464",class:{
                             'is-invalid h-40':
                               _vm.submittedPersonel && this.married == '',
                           },attrs:{"disabled":_vm.disabled == 0,"items":_vm.marriedItem,"label":"","dense":"","outlined":""},model:{value:(_vm.married),callback:function ($$v) {_vm.married=$$v},expression:"married"}}),(this.submittedPersonel && this.married == '')?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Choose Married ")]):_vm._e()],1)]),_c('div',[(_vm.disabled != 0 && _vm.married != 'Yes')?_c('div',{staticClass:"marg-l-24 marg-t-34"},[_c('v-btn',{staticClass:"\n                                 btn_width_96\n                                 h-40\n                                 brl_clr_btn\n                                 fsize14\n                                 textTransform_None\n                                 bgr_clr_btn_save\n                               ",attrs:{"depressed":"","plain":"","retain-focus-on-click":"","ripple":""},on:{"click":function($event){return _vm.personaldetailsSave()}}},[_vm._v(" Next ")])],1):_vm._e()]),(this.married == 'Yes')?_c('v-row',{staticClass:"marg-0 marg-t-10"},[_c('v-col',{staticClass:"pa-0 padd-0 max_w-225",attrs:{"cols":"5","sm":"5","md":"5","xs":"5"}},[_c('div',{staticClass:"h-72"},[_c('div',{staticClass:"custom-label fsize14 clr_55585a"},[_vm._v(" Wife Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wifeName),expression:"wifeName"}],staticClass:"\n                               w-225\n                               h-40\n                               border_radius\n                               padd-8-16\n                               border-input\n                               fsize14\n                               outLine_none\n                             ",class:{
                               'is-invalid h-40':
                                 _vm.submittedPersonel && this.wifeName == '',
                             },attrs:{"type":"text","name":"","disabled":_vm.disabled == 0,"placeholder":"Enter your Spouse Name","autocomplete":"off"},domProps:{"value":(_vm.wifeName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.wifeName=$event.target.value}}}),(
                               this.submittedPersonel && this.wifeName == ''
                             )?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Enter Wife Name ")]):_vm._e()])]),_c('v-col',{staticClass:"pa-0 padd-0 max_w-225",attrs:{"cols":"5","sm":"5","md":"5","xs":"5"}},[_c('div',{},[_c('div',{staticClass:"h-72 marg-l-12 w-225"},[_c('div',{staticClass:"custom-label fsize14 clr_55585a"},[_vm._v(" Wife Mob No ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wifeMobNo),expression:"wifeMobNo"}],staticClass:"\n                                 w-225\n                                 h-40\n                                 border_radius\n                                 padd-8-16\n                                 border-input\n                                 fsize14\n                                 outLine_none\n                               ",class:{
                                 'is-invalid h-40':
                                   _vm.submittedPersonel && this.wifeMobNo == '',
                               },attrs:{"type":"text","name":"","maxlength":"10","disabled":_vm.disabled == 0,"placeholder":"Enter your Wife Mob No","autocomplete":"off","oninput":"this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*?)\\..*/g, '$1');"},domProps:{"value":(_vm.wifeMobNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.wifeMobNo=$event.target.value}}}),(
                                 this.submittedPersonel && this.wifeMobNo == ''
                               )?_c('div',{staticClass:"fsize12 text-danger marg-t-4"},[_vm._v(" Please Enter Wife Mob No ")]):_vm._e()])])]),_c('v-col',{staticClass:"pa-0 padd-0 max_w-225",attrs:{"cols":"2","sm":"2","md":"2","xs":"2"}},[(_vm.disabled != 0)?_c('div',{staticClass:"marg-l-24 marg-t-20"},[_c('v-btn',{staticClass:"\n                                 btn_width_96\n                                 h-40\n                                 brl_clr_btn\n                                 fsize14\n                                 textTransform_None\n                                 bgr_clr_btn_save\n                               ",attrs:{"depressed":"","plain":"","retain-focus-on-click":"","ripple":""},on:{"click":function($event){return _vm.personaldetailsSave()}}},[_vm._v(" Next ")])],1):_vm._e()])],1):_vm._e()],1)]),_c('v-col',{staticClass:"pa-0 padd-0",attrs:{"cols":"3","sm":"3","md":"3","xs":"3"}})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

    <v-responsive min-width="1302">
       <v-progress-circular class="loader" size='70' color="black" v-if="loader" indeterminate ></v-progress-circular>
  
        <v-app class="bgr_clr_f9f9f9">
                
            <div class="bgr_clr_f9f9f9">
                <div class="padd-t-8 padd-b-8 padd-l-24">
                    <!-- <span class="fsize12 clr-1867c0"
                                    >Customer / New Customer Creation</span
                                  > -->
                    <v-breadcrumbs :items="items">
                        <template v-slot:item="{ item }">
                      <v-breadcrumbs-item
                        class="fsize12 clr-1867c0"
                        @click="$router.push('/Petition')"
                        :disabled="item.disabled"
                      >
                        {{ item.text.toUpperCase() }}
                      </v-breadcrumbs-item>
 </template>
          </v-breadcrumbs>
        </div>
        <div class="padd-t-16 padd-b-16 padd-l-24">
          <span class="fsize20 clr-000 fw-bold">New Petition Creation</span>
        </div>
        <div class="margin_bottom-7rem">
          <div class="marg-t-0 padd-l-24 padd-r-24">
            <v-card class="wholeCard padd-t-24 padd-0-40 min-height_1308">
              <v-row class="marg-0">
                <!-- <div class="marg-t-16"> -->
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div class="fsize14 clr-000 fw-bold padd-t-24">
                    Petitionor Details
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  <div class="padd-t-24">
                    <div class="w-464 h-72">
                      <div class="custom-label fsize14 clr_55585a">Name</div>

                      <input
                        type="text"
                        name=""
                        class="
                          w-464
                          h-40
                          border_radius
                          padd-8-16
                          border-input
                          fsize14
                          outLine_none
                        "
                        v-model="name"
                        :disabled="disabledNext == 1"
                        maxlength="100"
                        placeholder="Enter your Name"
                        autocomplete="off"
                        :class="{
                          'is-invalid': submitted && this.name == '',
                        }"
                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                      />
                      <div
                        class="fsize12 text-danger marg-t-4"
                        v-if="this.submitted && this.name == ''"
                      >
                        Please Enter The Name
                      </div>
                    </div>

                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          AADHAR CARD
                        </div>

                        <input
                          type="text"
                          name=""
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          :disabled="disabledNext == 1"
                          v-model="aadharCard"
                          maxlength="12"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          placeholder="Enter your AADHAR CARD"
                          autocomplete="off"
                          :class="{
                            'is-invalid': submitted && this.aadharCard == '',
                          }"
                        />
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="this.submitted && this.aadharCard == ''"
                        >
                          Please Enter AADHAR CARD Number
                        </div>
                      </div>
                    </div>


                    <v-row class="marg-0 marg-t-10">
                      <!-- <div class="marg-t-16"> -->

                      <v-col
                        cols="5"
                        sm="5"
                        md="5"
                        xs="5"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72">
                          <div class="custom-label fsize14 clr_55585a">
                            Mobile Number
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            :disabled="disabledNext == 1"
                            v-model="mobileNo"
                            id="Name1"
                            placeholder="Enter your Mobile Number"
                            autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            maxlength="10"
                            :class="{
                              'is-invalid': submitted && this.mobileNo == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submitted && this.mobileNo == ''"
                          >
                            Please Enter Mobile Number
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="5"
                        sm="5"
                        md="5"
                        xs="5"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72 marg-l-12 w-225">
                          <div class="custom-label fsize14 clr_55585a">
                            Whatsapp Number
                            <button @click="copyTextValue()">Mob No</button>
                          </div>

                          <input
                            type="text"
                            name=""
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            :disabled="disabledNext == 1"
                            v-model="whatsappNo"
                            maxlength="10"
                            id="Name2"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            placeholder="Enter your  Whatsapp Number"
                            autocomplete="off"
                            :class="{
                              'is-invalid': submitted && this.whatsappNo == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submitted && this.whatsappNo == ''"
                          >
                            Please Enter Whatsapp Number
                          </div>
                        </div>
                      </v-col>

                      <v-col
                        cols="2"
                        sm="2"
                        md="2"
                        xs="2"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div v-if="this.customerNext == true">
                          <div class="marg-l-24 marg-t-20">
                            <v-btn
                              class="
                                btn_width_96
                                h-40
                                brl_clr_btn
                                fsize14
                                textTransform_None
                                bgr_clr_btn_save
                              "
                              depressed
                              plain
                              retain-focus-on-click
                              ripple
                              @click="customerdetailsSave()"
                            >
                              Next
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <!-- <div class="">
                      <div class="fright">
                        <v-btn
                          class="
                            btn_width_96
                            h-40
                            brl_clr_btn
                            fsize14
                            textTransform_None
                            bgr_clr_btn_save
                          "
                          depressed
                          plain
                          retain-focus-on-click
                          ripple
                          @click="customerdetailsSave()"
                        >
                          Save
                        </v-btn>
                      </div>
                    </div> -->
                  </div>
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                </v-col>
                <!-- </div> -->
              </v-row>

              <!-- --------------------second Line----------------------------- -->

              <div>
                <hr class="lineBelowHead marg-t-32" />

                <v-row class="marg-0">
                  <!-- <div class="marg-t-16"> -->
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div class="fsize14 clr-000 fw-bold padd-t-24">
                      Address Details
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="row marg-0 padd-t-24">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize14 clr_55585a">
                          District
                        </div>

                        <v-select
                        
                          class="fsize14 marg_top w-464"
                          :items="customerTypeItem"
                          v-model="customerType"
                          label=""
                          dense
                          outlined
                          :class="{
                            'is-invalid h-40':
                              submittedPersonel && this.customerType == '',
                          }"
                        ></v-select>
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="
                            this.submittedPersonel && this.customerType == ''
                          "
                        >
                          Please Choose Customer Type
                        </div>
                      </div>

                      <div>
                        <div class="marg-t-16">
                          <div class="w-464 h-72">
                            <div class="custom-label fsize13 clr_55585a">
                              Taluk
                            </div>

                
                            <v-select
                             
                              class="fsize14 marg_top w-464"
                              v-model="dealerName"
                              :items="dealerlists"
                              label=""
                              dense
                              outlined
                              :class="{
                                'is-invalid h-40':
                                  submittedPersonel && this.area == '',
                              }"
                            ></v-select>
                            
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="marg-t-16">
                          <div class="w-464 h-72">
                            <div class="custom-label fsize13 clr_55585a">
                              Block
                            </div>
 <v-select
                             
                              class="fsize14 marg_top w-464"
                              v-model="dealerName"
                              :items="dealerlists"
                              label=""
                              dense
                              outlined
                              :class="{
                                'is-invalid h-40':
                                  submittedPersonel && this.area == '',
                              }"
                            ></v-select>
              
                          
                          </div>
                        </div>
                      </div>

                   
                     
    <div>
                            <div  v-if="disabled != 0 && married != 'Yes'" class="marg-l-24 marg-t-34">
                              <v-btn
                                class="
                                  btn_width_96
                                  h-40
                                  brl_clr_btn
                                  fsize14
                                  textTransform_None
                                  bgr_clr_btn_save
                                "
                                depressed
                                plain
                                retain-focus-on-click
                                ripple
                                @click="personaldetailsSave()"
                              >
                                Next
                              </v-btn>
                            </div>
                          </div>
                      <v-row
                        class="marg-0 marg-t-10"
                        v-if="this.married == 'Yes'"
                      >
                        <!-- <div class="marg-t-16"> -->

                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                          xs="5"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="h-72">
                            <div class="custom-label fsize14 clr_55585a">
                              Wife Name
                            </div>

                            <input
                              type="text"
                              name=""
                              class="
                                w-225
                                h-40
                                border_radius
                                padd-8-16
                                border-input
                                fsize14
                                outLine_none
                              "
                              v-model="wifeName"
                              :disabled="disabled == 0"
                              placeholder="Enter your Spouse Name"
                              autocomplete="off"
                              :class="{
                                'is-invalid h-40':
                                  submittedPersonel && this.wifeName == '',
                              }"
                            />
                            <div
                              class="fsize12 text-danger marg-t-4"
                              v-if="
                                this.submittedPersonel && this.wifeName == ''
                              "
                            >
                              Please Enter Wife Name
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                          xs="5"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="">
                            <div class="h-72 marg-l-12 w-225">
                              <div class="custom-label fsize14 clr_55585a">
                                Wife Mob No
                              </div>

                              <input
                                type="text"
                                name=""
                                class="
                                  w-225
                                  h-40
                                  border_radius
                                  padd-8-16
                                  border-input
                                  fsize14
                                  outLine_none
                                "
                                maxlength="10"
                                v-model="wifeMobNo"
                                :disabled="disabled == 0"
                                placeholder="Enter your Wife Mob No"
                                autocomplete="off"
                                :class="{
                                  'is-invalid h-40':
                                    submittedPersonel && this.wifeMobNo == '',
                                }"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              />
                              <div
                                class="fsize12 text-danger marg-t-4"
                                v-if="
                                  this.submittedPersonel && this.wifeMobNo == ''
                                "
                              >
                                Please Enter Wife Mob No
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="2"
                          sm="2"
                          md="2"
                          xs="2"
                          class="pa-0 padd-0 max_w-225"
                        >
                            <div  v-if="disabled != 0" class="marg-l-24 marg-t-20">
                              <v-btn
                                class="
                                  btn_width_96
                                  h-40
                                  brl_clr_btn
                                  fsize14
                                  textTransform_None
                                  bgr_clr_btn_save
                                "
                                depressed
                                plain
                                retain-focus-on-click
                                ripple
                                @click="personaldetailsSave()"
                              >
                                Next
                              </v-btn>
                            </div>
                          <!-- <div>
                            <div class="marg-l-24 marg-t-20">
                              <v-btn
                                class="
                                  btn_width_96
                                  h-40
                                  brl_clr_btn
                                  fsize14
                                  textTransform_None
                                  bgr_clr_btn_save
                                "
                                depressed
                                plain
                                retain-focus-on-click
                                ripple
                                @click="personaldetailsSave()"
                              >
                                Next
                              </v-btn>
                            </div>
                          </div> -->
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  </v-col>
                </v-row>
              </div>

              <!-- ----------------------Third Line-------------------------------------------- -->

              <div>
                <hr class="lineBelowHead marg-t-32" />
                <v-row class="marg-0">
                  <!-- <div class="marg-t-16"> -->
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div class="fsize14 clr-000 fw-bold padd-t-24">
                     Problem Type
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
              <div>
                        <div class="marg-t-16">
                          <div class="w-464 h-72">
                            <div class="custom-label fsize13 clr_55585a">
                              Department
                            </div>

                
                            <v-select
                             
                              class="fsize14 marg_top w-464"
                              v-model="dealerName"
                              :items="Departmenttype"
                              label=""
                              dense
                              outlined
                              :class="{
                                'is-invalid h-40':
                                  submittedPersonel && this.area == '',
                              }"
                            ></v-select>
                          </div>
                        </div>
                                    <div>
                        <div class="marg-t-16">
                          <div class="w-464 h-72">
                            <div class="custom-label fsize13 clr_55585a">
                              Location Type
                            </div>
 <v-select
                             
                              class="fsize14 marg_top w-464"
                              v-model="dealerName"
                              :items="Locationtype"
                              label=""
                              dense
                              outlined
                              :class="{
                                'is-invalid h-40':
                                  submittedPersonel && this.area == '',
                              }"
                            ></v-select>
              
                          
                          </div>
                        </div>
                      </div>
          
<div>
   <div class="marg-t-16">
                          <div class="w-464">
                            <div class="custom-label fsize13 clr_55585a">
                              Description
                            </div>
<v-textarea
          label=""
          auto-grow
          outlined
          rows="4"
          row-height="27"
        ></v-textarea>
              
                          
                          </div>
                        </div>
</div>
                      </div>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  </v-col>
                  <!-- </div> -->
                </v-row>

            
              </div>

              <hr class="lineBelowHead marg-t-32" />

              <!-- <div class="fsize14 clr-000"> Pettioner Detail</div> -->
            </v-card>
          </div>

          <div v-if="this.alldetailsSaveCancel == true">
            <div class="fright">
              <div class="row marg-t-20 marg-r-24">
                <div class="w-96">
                  <v-btn
                    class="
                      btn_width_96
                      h-40
                      brl_clr_btn
                      fsize14
                      textTransform_None
                      bgr_clr_btn_cancel
                    "
                    depressed
                    plain
                    retain-focus-on-click
                    ripple
                  >
                    Cancel
                  </v-btn>
                </div>
                <div class="marg-l-16 w-96">
                  <v-btn
                    class="
                      btn_width_96
                      h-40
                      brl_clr_btn
                      fsize14
                      textTransform_None
                      bgr_clr_btn_save
                    "
                    depressed
                    plain
                    retain-focus-on-click
                    ripple
                    @click="customerSave()"
                  >
                    Save
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app>
  </v-responsive>
</template>

<script>

import Vue from "vue";
export default {
  data() {
      return {
          educationalItem1: ["UG", "Graduate", "PG", "Professional", "others"],
          marriedItem: ["Yes", "No"],
          // areaItem: ["Thoraipakkam"],
          
          married: "",
          items: [{
                  text: "Petition",
                  disabled: false,
                  href: "Petition",
              },
              {
                  text: "New Petition Creation",
                  disabled: true,
                  href: "breadcrumbs_link_1",
              },
          ],
          name: "",
          alert: true,
          aadharCard: "",
          panCard: "",
          mobileNo: "",
          whatsappNo: "",
          customerType: "",
          area: "",
          ifscCode: "",
          bankName: "",
          bankAddress: "",
          accountNo: "",
          ConfirmAccountNo: "",
          wifeName: "",
          wifeMobNo: "",
          submitted: false,
          submittedPersonel: false,
          submittedBank: false,
          personalDetailsAll: false,
          linkAadharDigilocker: false,
          bankAccountDetails: false,
          alldetailsSaveCancel: false,
          enable: true,
          submitDisabled: "",
          loader: false,
          // :disabled='isDisabled'
          customerNext: true,
          personalNextbtn: false,
          bankDetailNextbtn: false,
          disabled: 0,
          disabledNext: 0,
          personelNext: 0,
          areaList: "",
          arealistsone: [],
          dealerlists: [],
          brokerlists: [],
          areaItem: "",
          dealerName: "",
          brokerName: "",
          userid: "",
          loader: false,
          Departmenttype:["Electricity", "Water", "Highways", "Corporation" ] ,
          Locationtype:["Residence", "Commercial Property", "Public Property"]
          // desserts: []
      };
  },

  computed: {
      isDisabled: function() {
          return !this.custom;
      },
  },

  methods: {
      customerSave() {
          this.submitted = true;
          this.$router.push('/customer');
         
      },
      customerdetailsSave() {
        this.loader = true
          this.submitted = true;
          if (
              this.name == "" ||
              this.aadharCard == "" ||
              this.panCard == "" ||
              this.mobileNo == "" ||
              this.whatsappNo == ""
          ) {
              return false;
          }
          this.disabled = 1;
          this.disabledNext = 1;
          this.customerNext = false;
          this.personalNextbtn = true;

          /**
           * Method to Customer DetailsSave
           * @author Rajapandi
           * @since 29/10/2021
           * @updatedOn :
           * @updatedBy
           * @return na
           */
          let jsondata = {
              // user_name: this.name,
              // mobile_number: this.mobileNo,
              customer_name: this.name,
              mobile_number: this.mobileNo,
              whatsapp_no: this.whatsappNo,
              pan_card: this.panCard,
              aadhar_no: this.aadharCard,
              branch_id: "1",
          };
          httpService.customerDetail(jsondata).then(
              (response) => {
                  if (response.status == 200) {
                      if (response.data["status"] == 1) {
                          localStorage.setItem(
                              "userprofile",
                              JSON.stringify(response.data["result"])
                          );
                          this.userid = response.data.result.user_id;
                          console.log(this.userid);
                           Vue.$toast.success("Customer details saved successfully", {
              position: "top-right",
              timeout: 2381,
            
              // optional options Object
            });
                      } else {
                        
                      }
                  }
              },
              (err) => {
                  console.log(err);
              }).finally(()=> {this.loader = false})

          this.personalDetailsAll = true;
          this.enable = true;
          // document.getElementById("customerdisbled").disabled = false;
      },

      personaldetailsSave() {
          this.submittedPersonel = true;
           this.loader = true
          this.bankDetailNextbtn = true;
          this.personelNext = 1;
          if (this.customerType == "" || this.area == "" || this.married == "") {
              return false;
          }

          if (this.married == "Yes") {
              if (this.wifeName == "" || this.wifeMobNo == "") {
                  return false;
              }
          }
          this.personalNextbtn = false;

          /**
           * Method to Customer DetailsSave
           * @author Rajapandi
           * @since 29/10/2021
           * @updatedOn :
           * @updatedBy
           * @return na
           */
          let jsondata = {
              user_id: this.userid,
              customer_type: this.customerType,
              address: this.area,
              marital_status: this.married,
              spouse_name: this.wifeName,
              spouse_mobile_number: this.wifeMobNo,
              branch_id: "1",
              //   if(this.married == 'Yes'){
              // },
          };
          httpService.customerPersonalDetail(jsondata).then(
              (response) => {
                  if (response.status == 200) {
                      if (response.data["status"] == 1) {
                          Vue.$toast.success("Other details saved successfully", {
              position: "top-right",
              timeout: 2381,
           
            
              // optional options Object
            });
                      } else {

                      }
                  }
              },
              (err) => {
                  console.log(err);
              }
          ).finally(()=> {this.loader = false})
          this.linkAadharDigilocker = true;
          this.disabled = 0;
      },
      bankdetailsSave() {
          this.loader = true
          this.submittedBank = true;
           this.personelNext == 1;
          if (
              this.ifscCode == "" ||
              this.accountNo == "" ||
              this.ConfirmAccountNo == "" ||
              this.accountNo != this.ConfirmAccountNo
          ) {
              return false;
          }
        
          // this.customerNext = false;
          this.bankDetailNextbtn = true;
          let jsondata = {
              user_id: this.userid,
              bank_address: this.bankAddress,
              account_no: this.accountNo,
              ifsc_code: this.ifscCode,
              branch_id: "1",
              bank_name: this.bankName,
          };
          httpService.customerBankDetail(jsondata).then(
              (response) => {
                  if (response.status == 200) {
                      if (response.data["status"] == 1) {
                         Vue.$toast.success("Bank details saved successfully", {
              position: "top-right",
              timeout: 2381,
            
            
              // optional options Object
            });
                      } else {

                      }
                  }
              },
              (err) => {
                  console.log(err);
              }
          ).finally(()=> {this.loader = false})
          this.alldetailsSaveCancel = true;
          
      },

      getifscCode() {
          let jsondata = {
              ifsc_code: this.ifscCode,
          };
          httpService.getifscCodeBankDetail(jsondata).then(
              (response) => {
                  if (response.status == 200) {
                      if (response.data["status"] == 1) {
                          this.bankName = response.data["result"]["BANK"];
                          this.bankAddress = response.data["result"]["ADDRESS"];
                      } else {}
                  }
              },
              (err) => {
                  console.log(err);
              }
          );
      },
      getAreaList() {
          httpService.getAreaList().then(
              (response) => {
                  if (response.status == 200) {
                      if (response.data["status"] == 1) {
                          this.area = response.data["result"]["areaName"];

                          this.arealistsone = [];
                          var areaList = response.data.result;
                          for (let item of areaList) {
                              this.arealistsone.push(item.areaName);
                              console.log(this.area);
                          }
                          console.log(this.arealistsone);
                      } else {}
                  }
              },
              (err) => {
                  console.log(err);
              }
          );
      },
      getBrokerList() {
          httpService.getBrokerList().then(
              (response) => {
                  if (response.status == 200) {
                      if (response.data["status"] == 1) {
                          var brokerList = response.data.result;
                          for (let item of brokerList) {
                              // this.brokerName = item.brokerName;
                              this.brokerlists.push(item.brokerName);
                          }
                      } else {}
                  }
              },
              (err) => {
                  console.log(err);
              }
          );
      },
      getDelearList() {
          httpService.getDealerList().then(
              (response) => {
                  if (response.status == 200) {
                      if (response.data["status"] == 1) {
                          this.dealerlists = [];
                          var dealerList = response.data.result;
                          for (let item of dealerList) {
                              // this.dealerName = item.agentName;
                              this.dealerlists.push(item.agentName);
                              localStorage.setItem(
                                  "Dealerlist",
                                  JSON.stringify(this.dealerlists)
                              );
                          }
                      } else {}
                  }
              },
              (err) => {
                  console.log(err);
              }
          );
      },
      getbankDetails() {
          httpService.getBankDetail().then(
              (response) => {
                  if (response.status == 200) {
                      if (response.data["status"] == 1) {} else {
                          this.openNotification({
                              type: "danger",
                              message: response.data["reason"],
                              duration: 3000,
                          });
                      }
                  }
              },
              (err) => {
                  console.log(err);
                  // if (err.response.status == 401) {
                  //   this.openNotification({
                  //     type: "danger",
                  //     message: "Session expired",
                  //     duration: 4000,
                  //   });
                  //   this.$router.push("/");
                  //   localStorage.clear();
                  // }
              }
          );
      },
      digiLockerValidate() {
          let json = {
              user_id: this.userid,
          };

          httpService.digilocker(json).then((response) => {
              if (response.status == 200) {
                  if (response.data["status"] == 1) {
                      var digiLockerURL = response.data.result.redirectUrl;
                      window.open(response.data.result.redirectUrl, "_self");
                      console.log(digiLockerURL);
                  }
              }
          });
      },
      copyTextValue() {
          // var text1 = bf ? document.getElementById("Name1").value : "";
          // document.getElementById("Name2").value = text1;
          this.mobileNo = this.whatsappNo;
      },
  },

  created(){
      setTimeout(()=>{
         this.alert = false
      },3000)

      
  },

  mounted() {
      this.getAreaList();
      this.getBrokerList();
      this.getDelearList();
  }
};
</script> 

<style>
.v-input--is-disabled:not(.v-input--is-readonly) {
    pointer-events: none;
    background: #e9ecef !important;
    height: 40px !important;
}

[disabled] {
    cursor: default;
    background: #e9ecef !important;
}
</style>